<script setup>
const storyblokApi = useStoryblokApi();
const { locale } = useI18n();
const query = useRoute().query;
const localePath = useLocalePath();

const footer = await useAsyncStoryblok('global/footer', {
    version: 'published',
    resolve_links: 'url',
    language: locale.value,
});

const blok = ref(footer.value.content.global[0]);
</script>

<template>
    <!-- Block start -->
    <footer class="bg-midnight-blue dark:bg-midnight-blue-dark">
        <div class="p-8 mx-auto max-w-screen-xl md:p-20">
            <div class="grid grid-cols-2 gap-8 lg:grid-cols-6">
                <div class="col-span-2">
                    <NuxtLink
                        :to="{ path: localePath('/home'), query }"
                        class="flex items-center mb-2 text-2xl font-semibold text-white sm:mb-0 dark:text-white"
                    >
                        <img :src="blok.logo.filename" class="mr-3 h-7 sm:h-9" alt="Tiptapp Logo" />
                    </NuxtLink>
                    <p class="my-4 font-light text-gray-400">
                        {{ blok.text }}
                    </p>
                    <div class="flex my-8">
                        <a
                            href="https://tiptappdl.onelink.me/4MT6/mainwebsitedliphone"
                            target="_blank"
                            data-ceid="download-app"
                            data-location="footer"
                        >
                            <img
                                class="h-8 mr-4"
                                src="https://a.storyblok.com/f/198708/135x40/2f88ce29d3/app-store-badge-en.svg"
                                alt="App store badge"
                            />
                        </a>
                        <a
                            href="https://tiptappdl.onelink.me/4MT6/mainwebsitedlandroid"
                            target="_blank"
                            data-ceid="download-app"
                            data-location="footer"
                        >
                            <img
                                class="h-8 mr-4"
                                src="https://a.storyblok.com/f/198708/135x40/11b4537e93/google-play-badge-en.svg"
                                alt="Google play badge"
                            />
                        </a>
                    </div>

                    <StoryblokComponent :blok="blok.address[0]" />

                    <ul class="flex mt-5 space-x-6">
                        <StoryblokComponent
                            v-for="(item, index) in blok.socialLinks"
                            :id="item._uid"
                            :key="item._uid"
                            :blok="item"
                            :index="index"
                        />
                    </ul>

                    <div class="mt-8 mb-8">
                        <LanguageDropdown id="footer" />
                        <MarketDropdown id="footer" />
                    </div>
                </div>
                <StoryblokComponent
                    v-for="linkSection in blok.linkSections"
                    :key="linkSection._uid"
                    :blok="linkSection"
                />
            </div>
            <div
                class="flex flex-wrap justify-center uppercase text-center mx-auto font-light text-gray-600 mt-6 grid-flow-row"
            >
                <div v-for="city in blok.cities.split('\n')" class="p-4">{{ city }}</div>
            </div>
            <hr class="my-3 border-gray-700 sm:mx-auto dark:border-gray-700 lg:my-4" />
            <div
                class="md:flex justify-center text-center mx-auto font-light text-sm text-gray-600"
            >
                <div class="my-2">
                    <p>© 2023 Tiptapp™. All Rights Reserved.</p>
                </div>
                <div class="pl-4 my-2 text-gray-400">
                    <a
                        :href="blok.tosLink.cached_url"
                        :target="blok.tosLink.target"
                        class="hover:underline"
                        >{{ blok.tosText }}</a
                    >
                </div>
                <div class="pl-4 my-2 text-gray-400">
                    <a
                        :href="blok.ppLink.cached_url"
                        :target="blok.ppLink.target"
                        class="hover:underline"
                        >{{ blok.ppText }}</a
                    >
                </div>
            </div>
        </div>
    </footer>
    <!-- Block end -->
</template>

<script setup>
const route = useRoute();

const props = defineProps({
    class: {
        type: String,
        default: () => '',
    },
    // This is a Storyblok Link Object
    to: {
        type: Object,
        default: () => ({}),
    },
});

const href = computed(() => {
    if (props.to.linktype === 'story') {
        // This is an internal link, maybe with an anchor
        return {
            path: props.to.cached_url,
            query: route.query,
            hash: props.to.anchor ? `#${props.to.anchor}` : '',
        };
    } else {
        return props.to.cached_url;
    }
});
</script>

<template>
    <NuxtLink :to="href" :class="props.class" :target="props.to.target">
        <slot></slot>
    </NuxtLink>
</template>

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import type { CookieConsentConfig } from 'vanilla-cookieconsent';

declare let dataLayer: any;

(window as any).dataLayer = (window as any).dataLayer || [];
function gtag(..._args: any[]) {
    dataLayer?.push(arguments);
}

const onGtmConsentGranted = () => {
    gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
        security_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
    });
    dataLayer.push({ event: 'consentUpdated' });
};

export default defineNuxtPlugin({
    name: 'vanilla-cookieconsent',
    parallel: true,
    setup() {
        gtag('consent', 'update', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
            security_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
        });

        if (
            /_storyblok|develop--|bsr-banner|in-app/.test(window.location.href) ||
            /inapp/.test(window.location.hostname)
        ) {
            console.log('Skipping consent dialog.');
            return;
        }

        CookieConsent.run(config);
    },
});

const config: CookieConsentConfig = {
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'middle center',
            flipButtons: false,
        },
        preferencesModal: {
            layout: 'box',
        },
    },
    categories: {
        necessary: {
            enabled: true,
            readOnly: true,
        },
        analytics: {
            autoClear: {
                cookies: [
                    { name: /^(_ga)/ },
                    { name: '_gid' },
                    { name: '_gcl_au' },
                    { name: '_fbp' },
                    { name: /^(_hj)/ },
                    { name: /^(intercom)/ },
                ],
            },
        },
        targeting: {
            autoClear: {
                cookies: [
                    { name: /^(_ga)/ },
                    { name: '_gid' },
                    { name: '_gcl_au' },
                    { name: /^(_drip_client)/ },
                ],
            },
        },
    },
    autoClearCookies: true,

    onFirstConsent: function ({ cookie }) {
        // callback triggered only once on the first accept/reject action
        if (cookie.categories.includes('analytics') || cookie.categories.includes('targeting')) {
            onGtmConsentGranted();
        }
    },

    onConsent: function ({ cookie }) {
        // callback triggered on the first accept/reject action, and after each page load
        onGtmConsentGranted();
    },

    onChange: function ({ cookie, changedCategories }: any) {
        // callback triggered when user changes preferences after consent has already been given
        console.log('onChange', cookie, changedCategories);
    },

    language: {
        autoDetect: 'browser',
        default: 'en',
        translations: {
            en: {
                consentModal: {
                    title: 'Can we use your data to tailor your experience?',
                    description:
                        'We use <a href="/cookies" class="cc-link">cookies</a> and data to<br />– Ensure basic functionality of the website<br />– Measure audience engagement and statistics<br /><br />If you choose to "Accept all", we will also use cookies and data together with our partners to<br />– Deliver and measure the effectiveness of ads<br />– Show personalised content<br />– Show personalised ads<br /><br /><button type="button" data-cc="show-preferencesModal" class="cc-link">Learn more and let me customize</button>.',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                },
                preferencesModal: {
                    title: 'Data collection preferences',
                    savePreferencesBtn: 'Save settings',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    closeIconLabel: 'Close',
                    sections: [
                        {
                            description:
                                'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/pp" class="cc-link">privacy policy</a>. For information about how Google uses your data see their <a href="https://policies.google.com/technologies/partner-sites" class="cc-link">Privacy &amp; Terms site</a>.',
                        },
                        {
                            title: 'Strictly necessary data',
                            description:
                                'This data is essential for the proper functioning of the website. It uses cookies to allow the website to remember the choices you have made in the past. For example, to remember your choice regarding data collection. Without these cookies, the website would not work properly. All of the data is anonymized and cannot be used to identify you.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Performance and Analytics data',
                            description:
                                'This data can be used by third parties to personalize ads. Without this you might see ads that are less relevant to you. It uses cookies to allow the website to remember the choices you have made in the past. For information about how Google uses your data see their <a href="https://policies.google.com/technologies/partner-sites" class="cc-link">Privacy &amp; Terms site</a>.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Name',
                                    domain: 'Domain',
                                    expiration: 'Expiration',
                                    description: 'Description',
                                },
                                body: [
                                    {
                                        name: '^_ga',
                                        domain: 'google.com',
                                        expiration: '2 years',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.com',
                                        expiration: '1 day',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gcl_au',
                                        domain: 'google.com',
                                        expiration: '3 months',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: 'facebook.com',
                                        expiration: '3 months',
                                        description: 'Facebook',
                                    },
                                    {
                                        name: '^_hjSessionUser',
                                        domain: 'hotjar.com',
                                        expiration: '1 year',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '^_hjSession_',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '_hjFirstSeen',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '_hjIncludedInSessionSample',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '^intercom-id',
                                        domain: 'intercom.com',
                                        expiration: '1 year',
                                        description: 'Intercom',
                                    },
                                    {
                                        name: '^intercom-session',
                                        domain: 'intercom.com',
                                        expiration: '1 day',
                                        description: 'Intercom',
                                    },
                                    {
                                        name: 'C',
                                        domain: 'adform.net',
                                        expiration: '1 month',
                                        description: 'Adform',
                                    },
                                    {
                                        name: '^CM',
                                        domain: 'adform.net',
                                        expiration: '2 weeks',
                                        description: 'Adform',
                                    },
                                    {
                                        name: 'uid',
                                        domain: 'adform.net',
                                        expiration: '2 months',
                                        description: 'Adform',
                                    },
                                    {
                                        name: '_uetsid',
                                        domain: 'crazyegg.com',
                                        expiration: '1 day',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_rdt_uuid',
                                        domain: 'crazyegg.com',
                                        expiration: '3 months',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga_SYQ20C9W7T',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga_SLYD2SP224',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: 'crazyegg.com',
                                        expiration: '3 months',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_drip_client_9574608',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_clsk',
                                        domain: 'crazyegg.com',
                                        expiration: '1 day',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_clck',
                                        domain: 'crazyegg.com',
                                        expiration: '1 year',
                                        description: 'Crazy Egg',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Advertisement and Targeting data',
                            description:
                                'This data can be used by third parties to personalize ads. Without this you might see ads that are less relevant to you. It uses cookies to collect information about how you use the website, which pages you visited and which links you clicked on. For information about how Google uses your data see their <a href="https://policies.google.com/technologies/partner-sites" class="cc-link">Privacy &amp; Terms site</a>.',
                            linkedCategory: 'targeting',
                            cookieTable: {
                                headers: {
                                    name: 'Name',
                                    domain: 'Domain',
                                    expiration: 'Expiration',
                                    description: 'Description',
                                },
                                body: [
                                    {
                                        name: '^_ga',
                                        domain: 'google.com',
                                        expiration: '2 years',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.com',
                                        expiration: '1 day',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gcl_au',
                                        domain: 'google.com',
                                        expiration: '3 months',
                                        description: 'Google',
                                    },
                                    {
                                        name: '^_drip_client',
                                        domain: 'drip.com',
                                        expiration: '2 years',
                                        description: 'Drip',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'More information',
                            description:
                                'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:hello@tiptapp.com">contact us</a>.',
                        },
                    ],
                },
            },
            de: {
                consentModal: {
                    title: 'Können wir Ihre Daten verwenden, um Ihre Erfahrungen zu verbessern?',
                    description:
                        'Wir verwenden <a href="/cookies" class="cc-link">Cookies</a> und Daten, um<br />- die grundlegende Funktionalität der Website zu gewährleisten<br />- das Engagement des Publikums zu messen und Statistiken zu erstellen<br /><br />Wenn Sie sich für die Option "Alles akzeptieren" entscheiden, verwenden wir Cookies und Daten auch zusammen mit unseren Partnern, um<br />- die Wirksamkeit von Anzeigen zu liefern und zu messen<br />- personalisierte Inhalte anzuzeigen<br />- personalisierte Anzeigen zu schalten<br /><br /><button type="button" data-cc="show-preferencesModal" class="cc-link">Erfahren Sie mehr und lassen Sie mich anpassen.</a>',
                    acceptAllBtn: 'Alles akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                },
                preferencesModal: {
                    title: 'Präferenzen bei der Datenerhebung',
                    savePreferencesBtn: 'Einstellungen speichern',
                    acceptAllBtn: 'Alles akzeptieren',
                    acceptNecessaryBtn: 'Alle ablehnen',
                    closeIconLabel: 'Schließen',
                    sections: [
                        {
                            description:
                                'Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und um Ihr Online-Erlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie sich ein- oder austragen möchten. Für weitere Einzelheiten zu Cookies und anderen sensiblen Daten lesen Sie bitte die vollständige <a href="/pp" class="cc-link">Datenschutzbestimmungen</a>. Informationen darüber, wie Google Ihre Daten verwendet, finden Sie unter <a href="https://policies.google.com/technologies/partner-sites?hl=de" class="cc-link">Datenschutzerklärung & Nutzungsbedingungen</a>.',
                        },
                        {
                            title: 'Streng notwendige Daten',
                            description:
                                'Diese Daten sind für das reibungslose Funktionieren der Website unerlässlich. Sie verwendet Cookies, damit sich die Website an die von Ihnen in der Vergangenheit getroffenen Entscheidungen erinnern kann. Zum Beispiel, um sich an Ihre Wahl bezüglich der Datenerfassung zu erinnern. Ohne diese Cookies würde die Website nicht richtig funktionieren. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Leistungs- und Analysedaten',
                            description:
                                'Diese Daten können von Dritten verwendet werden, um Anzeigen zu personalisieren. Andernfalls könnten Sie Anzeigen sehen, die für Sie weniger relevant sind. Es werden Cookies verwendet, damit sich die Website an die von Ihnen in der Vergangenheit getroffenen Entscheidungen erinnern kann. Informationen darüber, wie Google Ihre Daten verwendet, finden Sie unter <a href="https://policies.google.com/technologies/partner-sites?hl=de" class="cc-link">Datenschutzerklärung & Nutzungsbedingungen</a>.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Name',
                                    domain: 'Domain',
                                    expiration: 'Expiration',
                                    description: 'Description',
                                },
                                body: [
                                    {
                                        name: '^_ga',
                                        domain: 'google.com',
                                        expiration: '2 years',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.com',
                                        expiration: '1 day',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gcl_au',
                                        domain: 'google.com',
                                        expiration: '3 months',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: 'facebook.com',
                                        expiration: '3 months',
                                        description: 'Facebook',
                                    },
                                    {
                                        name: '^_hjSessionUser',
                                        domain: 'hotjar.com',
                                        expiration: '1 year',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '^_hjSession_',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '_hjFirstSeen',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '_hjIncludedInSessionSample',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '^intercom-id',
                                        domain: 'intercom.com',
                                        expiration: '1 year',
                                        description: 'Intercom',
                                    },
                                    {
                                        name: '^intercom-session',
                                        domain: 'intercom.com',
                                        expiration: '1 day',
                                        description: 'Intercom',
                                    },
                                    {
                                        name: 'C',
                                        domain: 'adform.net',
                                        expiration: '1 month',
                                        description: 'Adform',
                                    },
                                    {
                                        name: '^CM',
                                        domain: 'adform.net',
                                        expiration: '2 weeks',
                                        description: 'Adform',
                                    },
                                    {
                                        name: 'uid',
                                        domain: 'adform.net',
                                        expiration: '2 months',
                                        description: 'Adform',
                                    },
                                    {
                                        name: '_uetsid',
                                        domain: 'crazyegg.com',
                                        expiration: '1 day',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_rdt_uuid',
                                        domain: 'crazyegg.com',
                                        expiration: '3 months',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga_SYQ20C9W7T',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga_SLYD2SP224',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: 'crazyegg.com',
                                        expiration: '3 months',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_drip_client_9574608',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_clsk',
                                        domain: 'crazyegg.com',
                                        expiration: '1 day',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_clck',
                                        domain: 'crazyegg.com',
                                        expiration: '1 year',
                                        description: 'Crazy Egg',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Werbung und Targeting-Daten',
                            description:
                                'Diese Daten können von Dritten verwendet werden, um Anzeigen zu personalisieren. Andernfalls könnten Sie Anzeigen sehen, die für Sie weniger relevant sind. Sie verwendet Cookies, um Informationen darüber zu sammeln, wie Sie die Website nutzen, welche Seiten Sie besucht haben und auf welche Links Sie geklickt haben. Informationen darüber, wie Google Ihre Daten verwendet, finden Sie unter <a href="https://policies.google.com/technologies/partner-sites?hl=de" class="cc-link">Datenschutzerklärung & Nutzungsbedingungen</a>.',
                            linkedCategory: 'targeting',
                            cookieTable: {
                                headers: {
                                    name: 'Name',
                                    domain: 'Domain',
                                    expiration: 'Expiration',
                                    description: 'Description',
                                },
                                body: [
                                    {
                                        name: '^_ga',
                                        domain: 'google.com',
                                        expiration: '2 years',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.com',
                                        expiration: '1 day',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gcl_au',
                                        domain: 'google.com',
                                        expiration: '3 months',
                                        description: 'Google',
                                    },
                                    {
                                        name: '^_drip_client',
                                        domain: 'drip.com',
                                        expiration: '2 years',
                                        description: 'Drip',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Mehr Informationen',
                            description:
                                'Für weitere Informationen zu unseren Datenschutzbestimmungen und unserer Cookie Richtlinie, bitte <a class="cc-link" href="mailto:hello@tiptapp.com">Kontaktieren Sie uns</a>',
                        },
                    ],
                },
            },
            sv: {
                consentModal: {
                    title: 'Kan vi använda din data för att anpassa upplevelsen?',
                    description:
                        'Vi använder  <a href="/cookies" class="cc-link">cookies</a> och data för att<br />– Säkerställa webbsidans grundläggande funktionalitet<br />– Mäta engagemang och statistik<br /><br />Om du väljer att "Acceptera alla" så använder vi även cookies och data tillsammans med våra samarbetspartners för att<br />– Leverera och mäta effektiviteten av annonser<br />– Visa personanpassat innehåll<br />– Visa personanpassade annonser<br /><br /><button type="button" data-cc="show-preferencesModal" class="cc-link">Lär dig mer och låt mig anpassa.</button>',
                    acceptAllBtn: 'Acceptera alla',
                    acceptNecessaryBtn: 'Neka alla',
                },
                preferencesModal: {
                    title: 'Inställningar för datainsamling',
                    savePreferencesBtn: 'Spara inställningar',
                    acceptAllBtn: 'Acceptera alla',
                    acceptNecessaryBtn: 'Neka alla',
                    closeIconLabel: 'Stäng',
                    sections: [
                        {
                            description:
                                'Vi använder cookies för att säkerställa webbsidans grundläggande funktionalitet och för att förbättra din upplevelse. Du kan välja att godkänna eller neka cookies för varje kategori när du vill. För fler detaljer kring cookies och annan känslig data, var vänlig läs hela vår <a href="/pp" class="cc-link">integritetspolicy</a>. För information om hur Google använder din data, se deras sida om <a href="https://policies.google.com/technologies/partner-sites?hl=sv" class="cc-link">Integritet och användarvillkor</a>.',
                        },
                        {
                            title: 'Strikt nödvändig data',
                            description:
                                'Dessa cookies är nödvändiga för att webbsidan ska fungera korrekt.',
                            linkedCategory: 'necessary',
                        },
                        {
                            title: 'Data för utvärdering och analys',
                            description:
                                'Denna data kan användas av tredje part för att personanpassa annonser. Utan detta kan du se annonser som är mindre relevanta för dig. Cookies används för att låta webbsidan minnas de val du gjort tidigare. För information om hur Google använder din data, se deras sida om <a href="https://policies.google.com/technologies/partner-sites?hl=sv" class="cc-link">Integritet och användarvillkor</a>.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Namn',
                                    domain: 'Domän',
                                    expiration: 'Livslängd',
                                    description: 'Beskrivning',
                                },
                                body: [
                                    {
                                        name: '^_ga',
                                        domain: 'google.com',
                                        expiration: '2 years',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.com',
                                        expiration: '1 day',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gcl_au',
                                        domain: 'google.com',
                                        expiration: '3 months',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: 'facebook.com',
                                        expiration: '3 months',
                                        description: 'Facebook',
                                    },
                                    {
                                        name: '^_hjSessionUser',
                                        domain: 'hotjar.com',
                                        expiration: '1 year',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '^_hjSession_',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '_hjFirstSeen',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '_hjIncludedInSessionSample',
                                        domain: 'hotjar.com',
                                        expiration: '1 day',
                                        description: 'Hotjar',
                                    },
                                    {
                                        name: '^intercom-id',
                                        domain: 'intercom.com',
                                        expiration: '1 year',
                                        description: 'Intercom',
                                    },
                                    {
                                        name: '^intercom-session',
                                        domain: 'intercom.com',
                                        expiration: '1 day',
                                        description: 'Intercom',
                                    },
                                    {
                                        name: 'C',
                                        domain: 'adform.net',
                                        expiration: '1 month',
                                        description: 'Adform',
                                    },
                                    {
                                        name: '^CM',
                                        domain: 'adform.net',
                                        expiration: '2 weeks',
                                        description: 'Adform',
                                    },
                                    {
                                        name: 'uid',
                                        domain: 'adform.net',
                                        expiration: '2 months',
                                        description: 'Adform',
                                    },
                                    {
                                        name: '_uetsid',
                                        domain: 'crazyegg.com',
                                        expiration: '1 day',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_rdt_uuid',
                                        domain: 'crazyegg.com',
                                        expiration: '3 months',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga_SYQ20C9W7T',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga_SLYD2SP224',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_ga',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_fbp',
                                        domain: 'crazyegg.com',
                                        expiration: '3 months',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_drip_client_9574608',
                                        domain: 'crazyegg.com',
                                        expiration: 'year after next',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_clsk',
                                        domain: 'crazyegg.com',
                                        expiration: '1 day',
                                        description: 'Crazy Egg',
                                    },
                                    {
                                        name: '_clck',
                                        domain: 'crazyegg.com',
                                        expiration: '1 year',
                                        description: 'Crazy Egg',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Data för annonsering och riktad marknadsföring',
                            description:
                                'Denna data kan användas av tredje part för att personanpassa annonser. Utan detta kan du se annonser som är mindre relevanta för dig. Dessa cookies samlar information om hur du använder webbsidan, vilka sidor du besökt och vilka länkar du klickat på. För information om hur Google använder din data, se deras sida om <a href="https://policies.google.com/technologies/partner-sites?hl=sv" class="cc-link">Integritet och användarvillkor</a>.',
                            linkedCategory: 'targeting',
                            cookieTable: {
                                headers: {
                                    name: 'Namn',
                                    domain: 'Domän',
                                    expiration: 'Livslängd',
                                    description: 'Beskrivning',
                                },
                                body: [
                                    {
                                        name: '^_ga',
                                        domain: 'google.com',
                                        expiration: '2 years',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gid',
                                        domain: 'google.com',
                                        expiration: '1 day',
                                        description: 'Google',
                                    },
                                    {
                                        name: '_gcl_au',
                                        domain: 'google.com',
                                        expiration: '3 months',
                                        description: 'Google',
                                    },
                                    {
                                        name: '^_drip_client',
                                        domain: 'drip.com',
                                        expiration: '2 years',
                                        description: 'Drip',
                                    },
                                ],
                            },
                        },
                        {
                            title: 'Mer information',
                            description:
                                'För frågor kring vår policy kring cookies och dina val, var vänlig <a class="cc-link" href="mailto:hello@tiptapp.com">kontakta oss</a>.',
                        },
                    ],
                },
            },
        },
    },
};

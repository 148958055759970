<script setup>
import flagGB from '~/assets/svg/flag-gb.svg?url';
import flagSE from '~/assets/svg/flag-se.svg?url';
import flagDE from '~/assets/svg/flag-de.svg?url';
import flagPT from '~/assets/svg/flag-pt.svg?url';
import flagAT from '~/assets/svg/flag-at.svg?url';

const props = defineProps({
    id: {
        type: String,
        default: () => '',
    },
    class: {
        type: String,
        default: () => '',
    },
});

const { markets } = useAppConfig();

const route = useRoute();

const market = computed(() => {
    if (route.query.market === markets.uk) {
        return { name: 'United Kingdom', icon: flagGB };
    } else if (route.query.market === markets.se) {
        return { name: 'Sweden', icon: flagSE };
    } else if (route.query.market === markets.de) {
        return { name: 'Germany', icon: flagDE };
    } else if (route.query.market === markets.pt) {
        return { name: 'Portugal', icon: flagPT };
    } else if (route.query.market === markets.at) {
        return { name: 'Austria', icon: flagAT };
    } else return null;
});

function closeDropdown(event) {
    const classes = document.getElementById(`market-dropdown`).classList;
    classes.remove('block');
    classes.add('hidden');
}

function marketHref(market) {
    if (route.query._storyblok) {
        return `${route.path}?market=${market}&_storyblok=${route.query._storyblok}`;
    } else {
        return `${route.path}?market=${market}`;
    }
}
</script>

<template>
    <button
        type="button"
        data-dropdown-toggle="market-dropdown"
        class="inline-flex items-center text-gray-300 font-medium rounded-lg text-sm px-2.5 lg:px-5 py-2.5 mr-2 hover:bg-gray-800"
    >
        <div class="mr-1">
            <svg
                width="18px"
                height="18px"
                viewBox="0 0 18 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
            >
                <title>icon-position-grey</title>
                <g
                    id="icons/ad-details/icon-position-grey-copy"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                >
                    <path
                        id="Path"
                        d="M9,0 C5.69175,0 3,2.69175 3,6 C3,11.29275 8.485125,17.6048438 8.71875,17.8710938 C8.79,17.9524687 8.892,18 9,18 C9.11625,17.992125 9.21,17.9535937 9.28125,17.8710938 C9.514875,17.6003438 15,11.179875 15,6 C15,2.69175 12.30825,0 9,0 Z"
                        fill="#999999"
                    ></path>
                    <path
                        id="Path"
                        d="M9,4.125 C10.242375,4.125 11.25,5.132625 11.25,6.375 C11.25,7.617375 10.242375,8.625 9,8.625 C7.757625,8.625 6.75,7.617375 6.75,6.375 C6.75,5.132625 7.757625,4.125 9,4.125 Z"
                        fill="#FFFFFF"
                    ></path>
                </g>
            </svg>
        </div>
        {{ (market && market.name) || 'Select market' }}
        <svg
            class="ml-1 w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
            ></path>
        </svg>
    </button>

    <div
        id="market-dropdown"
        class="hidden z-50 my-4 w-48 text-base list-none rounded divide-y divide-gray-100 shadow-xl bg-gray-800"
        @click="closeDropdown"
    >
        <ul class="py-1" role="none">
            <li>
                <a
                    :href="marketHref(markets.uk)"
                    class="block py-2 px-4 text-sm text-gray-400 hover:bg-gray-600 hover:text-white"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagGB" alt="GB flag" />
                        United Kingdom
                    </div>
                </a>
            </li>
            <li>
                <a
                    :href="marketHref(markets.de)"
                    class="block py-2 px-4 text-sm text-gray-400 hover:bg-gray-600 hover:text-white"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagDE" alt="DE flag" />
                        Germany
                    </div>
                </a>
            </li>
            <li>
                <a
                    :href="marketHref(markets.se)"
                    class="block py-2 px-4 text-sm text-gray-400 hover:bg-gray-600 hover:text-white"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagSE" alt="SE flag" />
                        Sweden
                    </div>
                </a>
            </li>
            <li>
                <a
                    :href="marketHref(markets.pt)"
                    class="block py-2 px-4 text-sm text-gray-400 hover:bg-gray-600 hover:text-white"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagPT" alt="PT flag" />
                        Portugal
                    </div>
                </a>
            </li>
            <li>
                <a
                    :href="marketHref(markets.at)"
                    class="block py-2 px-4 text-sm text-gray-400 hover:bg-gray-600 hover:text-white"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagAT" alt="AT flag" />
                        Austria
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAdCampaign, LazyArticleGrid, LazyArticle, LazyAsSeenOnGrid, LazyBlogIndex, LazyBsrBannerTall, LazyBsrBannerWide, LazyBsrBanner, LazyCampaign, LazyConditionalLangItem, LazyConditionalRegionItem, LazyCreateCampaign, LazyCtaLink, LazyCtaSvgLink, LazyDownloadNowCtaLink, LazyEnquiryForm, LazyErrorPage, LazyFaqItem, LazyFaqList, LazyFeatureCard, LazyFeatureGridWithDescription, LazyFeatureGrid, LazyFeatureItem, LazyFeatureListCard, LazyFeatureListWithCtas, LazyFooterAddress, LazyFooterLinkSectionLink, LazyFooterLinkSection, LazyFooterSocialLink, LazyFooter, LazyFormWithDoneState, LazyGlobal, LazyGrid3, LazyGrid4, LazyGridItem, LazyHeroInline, LazyHeroSectionWithBookCover, LazyHeroWithCard, LazyHeroWithVideo, LazyHowItWorksCard, LazyInviteForm, LazyInviteLanding, LazyLatestAds, LazyMainPage, LazyNewsCard, LazyNewsGrid, LazyPricingTool, LazyRichTextArticle, LazyServicefinderForm, LazyTeaserItem, LazyTestimonialCard, LazyTestimonialGrid, LazyTopNavigationDropdownLink, LazyTopNavigationDropdown, LazyTopNavigationLink, LazyTopNavigation, LazyWarningBanner } from '#components'
const lazyGlobalComponents = [
  ["AdCampaign", LazyAdCampaign],
["ArticleGrid", LazyArticleGrid],
["Article", LazyArticle],
["AsSeenOnGrid", LazyAsSeenOnGrid],
["BlogIndex", LazyBlogIndex],
["BsrBannerTall", LazyBsrBannerTall],
["BsrBannerWide", LazyBsrBannerWide],
["BsrBanner", LazyBsrBanner],
["Campaign", LazyCampaign],
["ConditionalLangItem", LazyConditionalLangItem],
["ConditionalRegionItem", LazyConditionalRegionItem],
["CreateCampaign", LazyCreateCampaign],
["CtaLink", LazyCtaLink],
["CtaSvgLink", LazyCtaSvgLink],
["DownloadNowCtaLink", LazyDownloadNowCtaLink],
["EnquiryForm", LazyEnquiryForm],
["ErrorPage", LazyErrorPage],
["FaqItem", LazyFaqItem],
["FaqList", LazyFaqList],
["FeatureCard", LazyFeatureCard],
["FeatureGridWithDescription", LazyFeatureGridWithDescription],
["FeatureGrid", LazyFeatureGrid],
["FeatureItem", LazyFeatureItem],
["FeatureListCard", LazyFeatureListCard],
["FeatureListWithCtas", LazyFeatureListWithCtas],
["FooterAddress", LazyFooterAddress],
["FooterLinkSectionLink", LazyFooterLinkSectionLink],
["FooterLinkSection", LazyFooterLinkSection],
["FooterSocialLink", LazyFooterSocialLink],
["Footer", LazyFooter],
["FormWithDoneState", LazyFormWithDoneState],
["Global", LazyGlobal],
["Grid3", LazyGrid3],
["Grid4", LazyGrid4],
["GridItem", LazyGridItem],
["HeroInline", LazyHeroInline],
["HeroSectionWithBookCover", LazyHeroSectionWithBookCover],
["HeroWithCard", LazyHeroWithCard],
["HeroWithVideo", LazyHeroWithVideo],
["HowItWorksCard", LazyHowItWorksCard],
["InviteForm", LazyInviteForm],
["InviteLanding", LazyInviteLanding],
["LatestAds", LazyLatestAds],
["MainPage", LazyMainPage],
["NewsCard", LazyNewsCard],
["NewsGrid", LazyNewsGrid],
["PricingTool", LazyPricingTool],
["RichTextArticle", LazyRichTextArticle],
["ServicefinderForm", LazyServicefinderForm],
["TeaserItem", LazyTeaserItem],
["TestimonialCard", LazyTestimonialCard],
["TestimonialGrid", LazyTestimonialGrid],
["TopNavigationDropdownLink", LazyTopNavigationDropdownLink],
["TopNavigationDropdown", LazyTopNavigationDropdown],
["TopNavigationLink", LazyTopNavigationLink],
["TopNavigation", LazyTopNavigation],
["WarningBanner", LazyWarningBanner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

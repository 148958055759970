import type { RouterConfig } from '@nuxt/schema';

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
    async scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        const findEl = async (hash: string, x: number): Promise<Element | HTMLElement | null> => {
            return (
                document.querySelector(hash) ||
                new Promise((resolve, reject) => {
                    // Is this necessary?
                    if (x > 50) {
                        return resolve(null);
                    }
                    setTimeout(() => {
                        resolve(findEl(hash, x++));
                    }, 100);
                })
            );
        };

        if (to.hash) {
            let el = await findEl(to.hash, 0);
            if (el instanceof HTMLElement) {
                if ('scrollBehavior' in document.documentElement.style) {
                    return window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
                } else {
                    return window.scrollTo(0, el.offsetTop);
                }
            }
        }

        return { top: 0 };
    },
};

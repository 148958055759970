export default defineNuxtPlugin({
    name: 'blok-animations',
    parallel: true,
    setup() {
        let observer: IntersectionObserver;

        const callback = function (entries: any) {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('motion-safe:animate-fadeIn');
                    observer.unobserve(entry.target);
                } else {
                    entry.target.classList.remove('motion-safe:animate-fadeIn');
                }
            });
        };

        observer = new IntersectionObserver(callback);

        const targets = document.querySelectorAll('.js-show-on-scroll');
        targets.forEach(function (target) {
            target.classList.add('opacity-0');
            observer.observe(target);
        });
    },
});

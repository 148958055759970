import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/opt/build/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_KlVwwuJRCL from "/opt/build/repo/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import _01_cookieconsent_client_plammaq7aK from "/opt/build/repo/plugins/01.cookieconsent.client.ts";
import _02_blok_animations_client_ukOXy1IL7m from "/opt/build/repo/plugins/02.blok-animations.client.ts";
import _03_phone_number_util_client_U9gVyGHfWT from "/opt/build/repo/plugins/03.phone-number-util.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_KlVwwuJRCL,
  _01_cookieconsent_client_plammaq7aK,
  _02_blok_animations_client_ukOXy1IL7m,
  _03_phone_number_util_client_U9gVyGHfWT
]
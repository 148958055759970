// app.config.ts
export default defineAppConfig({
    defaultMarket: 'se' as const,
    markets: { uk: 'uk', de: 'de', pt: 'pt', se: 'se', at: 'at' },
    marketToCountry: {
        uk: 'GB' as const,
        de: 'DE' as const,
        pt: 'PT' as const,
        se: 'SE' as const,
        at: 'AT' as const,
    },
    // Note language / country is not a 1:1 mapping, so for example 'AT' is not included here
    languageToCountry: { en: 'GB', de: 'DE', pt: 'PT', sv: 'SE' },
});

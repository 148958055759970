<script setup>
const storyblokApi = useStoryblokApi();
const { locale } = useI18n();

const query = useRoute().query;

const { data } = await storyblokApi.get('cdn/stories/global/error-page', {
    version: query._storyblok ? 'draft' : 'published',
    resolve_links: 'url',
    language: locale.value,
});

const blok = ref(null);
blok.value = data.story.content.global[0];
</script>

<template>
    <div class="page">
        <TopNavigation />
        <section class="bg-white dark:bg-midnight-blue">
            <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto max-w-screen-sm text-center">
                    <img class="mx-auto mb-4" :src="blok.image.filename" alt="404 Not Found" />
                    <h2 class="mb-4 leading-none text-black dark:text-white">
                        {{ blok.title }}
                    </h2>
                    <p
                        class="mt-4 text-base font-normal text-gray-500 sm:text-xl dark:text-gray-400"
                    >
                        {{ blok.description }}
                    </p>
                    <BlokLink :to="blok.ctaLink" :replace="true">
                        <button
                            class="inline-flex items-center my-4 py-3 px-5 text-base font-medium text-center rounded-lg focus:ring-4 justify-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-900"
                        >
                            {{ blok.ctaLabel }}
                        </button>
                    </BlokLink>
                </div>
            </div>
        </section>
        <Footer />
    </div>
</template>

function getTheme() {
    if (!process.client) return 'light';
    if (
        localStorage.getItem('color-theme') === 'dark' ||
        (!('color-theme' in localStorage) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
        return 'dark';
    } else {
        return 'light';
    }
}

export default function useTheme() {
    const theme = ref(getTheme());

    watchEffect(() => {
        if (!process.client) return;
        document.documentElement.classList.remove('dark');
        if (theme.value === 'dark') document.documentElement.classList.add('dark');
    });

    const toggleTheme = () => {
        theme.value = theme.value === 'dark' ? 'light' : 'dark';
        localStorage.setItem('color-theme', theme.value);
    };

    return {
        theme,
        toggleTheme,
    };
}

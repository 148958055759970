import { PhoneNumberUtil } from 'google-libphonenumber';

export default defineNuxtPlugin({
    name: 'phone-number-util',
    parallel: true,
    setup(_nuxtApp) {
        const phoneUtil = PhoneNumberUtil.getInstance();
        _nuxtApp.provide('phoneUtil', phoneUtil);
    },
});

<script setup>
import flagGB from '~/assets/svg/flag-gb.svg?url';
import flagSE from '~/assets/svg/flag-se.svg?url';
import flagDE from '~/assets/svg/flag-de.svg?url';
import flagPT from '~/assets/svg/flag-pt.svg?url';

const props = defineProps({
    id: {
        type: String,
        default: () => '',
    },
    class: {
        type: String,
        default: () => '',
    },
});

const { locale } = useI18n();
const switchLocalePath = useSwitchLocalePath();

const language = computed(() => {
    if (locale.value === 'sv') {
        return { name: 'Swedish', icon: flagSE };
    } else if (locale.value === 'de') {
        return { name: 'German', icon: flagDE };
    } else if (locale.value === 'pt') {
        return { name: 'Portuguese', icon: flagPT };
    } else {
        return { name: 'English', icon: flagGB };
    }
});

// TODO - is there a better way to do this?
//        Flowbite dropdowns do not seem to close on selection
function closeDropdown() {
    const classes = document.getElementById(`language-dropdown-${props.id}`).classList;
    classes.remove('block');
    classes.add('hidden');
}
</script>

<template>
    <button
        type="button"
        :data-dropdown-toggle="`language-dropdown-${id}`"
        :class="`${props.class} flex sm:inline-flex items-center ${
            id === 'footer' ? 'language-dropdown-footer' : 'language-dropdown'
        }`"
    >
        <img class="h-5 w-5 mr-2" :src="language.icon" :alt="`${language.name} flag`" />
        {{ language.name }}
        <svg
            class="ml-1 w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
            ></path>
        </svg>
    </button>

    <div
        :class="`hidden ${
            id === 'footer' ? 'language-dropdown-area-footer' : 'language-dropdown-area'
        }`"
        :id="`language-dropdown-${id}`"
        @click="closeDropdown"
    >
        <ul class="py-1" role="none">
            <li>
                <a
                    :href="switchLocalePath('en')"
                    :class="`${
                        id === 'footer' ? 'language-dropdown-item-footer' : 'language-dropdown-item'
                    }`"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagGB" alt="GB flag" />
                        English
                    </div>
                </a>
            </li>
            <li>
                <a
                    :href="switchLocalePath('de')"
                    :class="`${
                        id === 'footer' ? 'language-dropdown-item-footer' : 'language-dropdown-item'
                    }`"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagDE" alt="DE flag" />
                        Deutsch
                    </div>
                </a>
            </li>
            <li>
                <a
                    :href="switchLocalePath('sv')"
                    :class="`${
                        id === 'footer' ? 'language-dropdown-item-footer' : 'language-dropdown-item'
                    }`"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagSE" alt="SE flag" />
                        Swedish
                    </div>
                </a>
            </li>
            <li>
                <a
                    :href="switchLocalePath('pt')"
                    :class="`${
                        id === 'footer' ? 'language-dropdown-item-footer' : 'language-dropdown-item'
                    }`"
                    role="menuitem"
                >
                    <div class="inline-flex items-center">
                        <img class="h-3.5 w-3.5 mr-2" :src="flagPT" alt="PT flag" />
                        Portugese
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<style scoped>
.language-dropdown {
    @apply text-gray-800 dark:text-gray-300 hover:bg-gray-50 focus:ring-gray-300 font-medium rounded-lg text-sm px-2.5 lg:px-5 py-2.5 mr-2 dark:hover:bg-gray-800;
}

.language-dropdown-footer {
    @apply text-gray-300 focus:ring-gray-300 font-medium rounded-lg text-sm px-2.5 lg:px-5 py-2.5 mr-2 hover:bg-gray-800;
}

.language-dropdown-area {
    @apply z-50 my-4 w-48 text-base list-none bg-white rounded divide-y divide-gray-100 shadow-xl dark:bg-gray-800;
}

.language-dropdown-area-footer {
    @apply z-50 my-4 w-48 text-base list-none rounded divide-y divide-gray-100 shadow-xl bg-gray-800;
}

.language-dropdown-item {
    @apply block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white;
}

.language-dropdown-item-footer {
    @apply block py-2 px-4 text-sm text-gray-400 hover:bg-gray-600 hover:text-white;
}
</style>

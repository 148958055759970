import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as _91adId_93TzSGfE1gjoMeta } from "/opt/build/repo/pages/fb-campaign/[adId].vue?macro=true";
import { default as _91_46_46_46slug_93MNmlUVAmZ3Meta } from "/opt/build/repo/pages/in-app/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_937GSwhM3iV8Meta } from "/opt/build/repo/pages/invite/[...slug].vue?macro=true";
import { default as _91code_93DvYcPjhi9OMeta } from "/opt/build/repo/pages/redeem/[code].vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en___default",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "slug___sv",
    path: "/sv/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "slug___pt",
    path: "/pt/:slug(.*)*",
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "fb-campaign-adId___en___default",
    path: "/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue")
  },
  {
    name: "fb-campaign-adId___en",
    path: "/en/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue")
  },
  {
    name: "fb-campaign-adId___de",
    path: "/de/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue")
  },
  {
    name: "fb-campaign-adId___sv",
    path: "/sv/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue")
  },
  {
    name: "fb-campaign-adId___pt",
    path: "/pt/fb-campaign/:adId()",
    meta: _91adId_93TzSGfE1gjoMeta || {},
    component: () => import("/opt/build/repo/pages/fb-campaign/[adId].vue")
  },
  {
    name: "in-app-slug___en___default",
    path: "/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue")
  },
  {
    name: "in-app-slug___en",
    path: "/en/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue")
  },
  {
    name: "in-app-slug___de",
    path: "/de/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue")
  },
  {
    name: "in-app-slug___sv",
    path: "/sv/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue")
  },
  {
    name: "in-app-slug___pt",
    path: "/pt/in-app/:slug(.*)*",
    meta: _91_46_46_46slug_93MNmlUVAmZ3Meta || {},
    component: () => import("/opt/build/repo/pages/in-app/[...slug].vue")
  },
  {
    name: "invite-slug___en___default",
    path: "/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue")
  },
  {
    name: "invite-slug___en",
    path: "/en/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue")
  },
  {
    name: "invite-slug___de",
    path: "/de/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue")
  },
  {
    name: "invite-slug___sv",
    path: "/sv/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue")
  },
  {
    name: "invite-slug___pt",
    path: "/pt/invite/:slug(.*)*",
    meta: _91_46_46_46slug_937GSwhM3iV8Meta || {},
    component: () => import("/opt/build/repo/pages/invite/[...slug].vue")
  },
  {
    name: "redeem-code___en___default",
    path: "/redeem/:code()",
    component: () => import("/opt/build/repo/pages/redeem/[code].vue")
  },
  {
    name: "redeem-code___en",
    path: "/en/redeem/:code()",
    component: () => import("/opt/build/repo/pages/redeem/[code].vue")
  },
  {
    name: "redeem-code___de",
    path: "/de/redeem/:code()",
    component: () => import("/opt/build/repo/pages/redeem/[code].vue")
  },
  {
    name: "redeem-code___sv",
    path: "/sv/redeem/:code()",
    component: () => import("/opt/build/repo/pages/redeem/[code].vue")
  },
  {
    name: "redeem-code___pt",
    path: "/pt/redeem/:code()",
    component: () => import("/opt/build/repo/pages/redeem/[code].vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/en-GB-sitemap.xml",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sv-SE-sitemap.xml",
    component: component_45stubRU5sOBTecH
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/pt-PT-sitemap.xml",
    component: component_45stubRU5sOBTecH
  }
]